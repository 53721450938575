import request from '@/until/request.js'

// 获取库存状况表
export function getCondition(data){
  return request({
    url:"/gm/webItyRpt/condition",
    method: 'get',
    params: { ...data }
  })
}
// 获取库存分布表
export function getSummary(data){
  return request({
    url:"/gm/inventoryReport/summary",
    method: 'get',
    params: { ...data }
  })
}
// 查看批次状况表
export function getBatchCondition(data){
  return request({
    url:"/gm/inventoryReport/batchCondition",
    method: 'get',
    params: { ...data }
  })
}
// 查看库存分布表
export function getDistribution(data){
  return request({
    url:"/gm/inventoryReport/distribution",
    method: 'get',
    params: { ...data }
  })
}
// 查看收发明细表
export function getSummaryDetail(data){
  return request({
    url:"/gm/inventoryReport/summaryDetail",
    method: 'get',
    params: { ...data }
  })
}