<template>
  <div class="view-wrap">
    <el-form :model="searchForm" class="F" inline>
      <el-form-item label="商品" prop="param" class="F">
        <el-input
          placeholder="请输入商品名称/编号/登记证号"
          clearable
          v-model="searchForm.param"
        ></el-input>
      </el-form-item>
      <el-form-item label="仓库">
        <el-select v-model="searchForm.wmsId" multiple placeholder="仓库">
          <el-option
            v-for="item in storehouse"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-cascader
          v-model="searchForm.productTypes"
          style="width: 100%"
          :props="typeProps"
          :options="typeList"
          :show-all-levels="false"
          placeholder="请选择类别"
          clearable
        >
          <template slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-select v-model="searchForm.sort">
          <el-option
            v-for="item in sorts"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="库存预警">
        <el-select v-model="searchForm.isEarly">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in earlys"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示零库存商品">
        <el-switch
          v-model="searchForm.isWms"
          :active-value="0"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="负库存商品">
        <el-switch
          v-model="searchForm.loseWms"
          :active-value="0"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" size="mini" @click="submitForm"
            >查询</el-button
          >
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border="">
      <!-- <el-table-column label="操作" align="center" width="150px">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="deInventoryDistribution(scope.row.id)"
            >库存分布</el-button
          >
          <el-button type="text" size="mini" @click="summary(scope.row)"
            >收发存汇总</el-button
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="code" label="商品编号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="商品名称" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.isLimit == '1'">
            {{ scope.row.name }}
            <img
              src="~@/assets/image/limit.png"
              alt=""
              srcset=""
              width="16"
              height="16"
            />
          </div>
          <div v-else>
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="登记证号" align="center">
        <template slot-scope="scope">
          {{ scope.row.regCode ? scope.row.regCode : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="商品类别" align="center">
      </el-table-column>
      <el-table-column prop="spec" label="净含量" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.netContent">{{
            scope.row.netContent + "/" + scope.row.netContentUnitName
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="基础单位" align="center">
      </el-table-column>
      <el-table-column prop="num" label="可用库存" align="center">
      </el-table-column>
      <el-table-column
        v-if="Config.isEarly == '1'"
        prop="totalPrice"
        label="安全库存"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.inventoryFloor">
            {{ scope.row.inventoryFloor + "~" + scope.row.inventoryCeiling }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="Config.isEarly == '1'"
        prop="isEarly"
        label="库存预警"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isEarly == '0'" style="color: #39cf06"
            >正常</span
          >
          <span v-if="scope.row.isEarly == '1'" style="color: #d9001bcc"
            >库存短缺</span
          >
          <span v-if="scope.row.isEarly == '2'" style="color: #648ffc"
            >库存过剩</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="price" label="平均成本" align="center">
      </el-table-column>
      <el-table-column prop="totalPrice" label="总成本" align="center">
        <template slot-scope="scope">
          {{ scope.row.totalPrice ? scope.row.totalPrice.toFixed(2) : "--" }}
        </template>
      </el-table-column> -->
    </el-table>
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isStatement="true"
          :checkArr="checkProductArr"
          @close="Visible = false"
          @SelectGoods="changeProductId"
        />
      </div>
    </miaDialog>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import SelectGoods from "@/components/pustorageTable/SelectGoods.vue";
import { getTheWarehouseList, getProductList } from "@/api/procurementSummary";
import { getCondition } from "@/api/inventoryTable";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
import { GetProductTypeList } from "@/api/productType";

export default {
  components: {
    SelectGoods,
    OpenAccount,
  },

  data() {
    return {
      info: {
        show: false,
      },
      Visible: false,
      searchForm: {
        wmsId: "",
        param: "",
        productTypes: "",
        sort: 1,
        isWms: 0,
        loseWms: 0,
        isEarly: "",
      }, //搜索条件
      productList: [], //商品列表
      storehouse: [], //供应商
      tableData: [], //导出组装的数据
      checkProductArr: [],
      checkProductName: "",
      sorts: [
        {
          name: "创建时间(由近到远)",
          id: 1,
        },
        {
          name: "创建时间(由远到近)",
          id: 2,
        },
        {
          name: "编号(由高到低)",
          id: 4,
        },
        {
          name: "编号(由低到高)",
          id: 3,
        },
        {
          name: "库存量(由高到低)",
          id: 6,
        },
        {
          name: "库存量(由低到高)",
          id: 5,
        },
      ],
      earlys: [
        {
          name: "正常",
          id: 0,
        },
        {
          name: "库存短缺",
          id: 1,
        },
        {
          name: "库存过剩",
          id: 2,
        },
      ],
      UnitList: [
        {
          id: "0",
          name: "毫升",
        },
        {
          id: "1",
          name: "克",
        },

        {
          id: "2",
          name: "千克",
        },
        {
          id: "3",
          name: "升",
        },
      ],
      Config: {},
      typeList: [],
      typeListName: [],
      typeProps: {
        value: "id",
        label: "name",
        children: "list",
      },
      typeId: [],
    };
  },
  created() {
    this.Config = JSON.parse(sessionStorage.getItem("config"));
    this.getConfigBills();
    this.getTheWarehouseList();
    this.getList();
    this.getProductList();
    this.getType();
  },

  methods: {
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
        }
      }
    },
    getType() {
      GetProductTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          this.typeListName = records[0].list;
          this.getTreeData(this.typeList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].list.length < 1) {
          // list若为空数组，则将删除
          delete data[i].list;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].list);
        }
      }
      return data;
    },
    handleChange(value) {
      if (value.length !== 0) {
        this.searchForm.typeId = value[value.length - 1];
      } else {
        this.searchForm.typeId = "";
      }
    },
    clearProduct() {
      this.checkProductArr = [];
    },
    checkProductArrs(val) {
      this.checkProductName =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.name;
              })
              .join("、");
      this.searchForm.productIds =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.id;
              })
              .join(",");
    },
    openProduct() {
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.SelectGoods.init("选择商品");
      });
    },
    changeProductId(list) {
      this.Visible = false;
      this.checkProductArr = list;
      this.checkProductArrs(list);
    },
    //跳转库存分布
    deInventoryDistribution(id) {
      this.$router.push({
        path: "/inventoryTable/inventoryDistributionTable/index",
        query: {
          id: id,
        },
      });
    },
    //跳转收发存汇总
    summary() {
      this.$router.push({
        path: "/inventoryTable/summary/index",
      });
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        // const multiHeader = [
        //   [
        //     "供应商",
        //     "商品编号",
        //     "商品名称",
        //     "规格",
        //     "条形码",
        //     "采购入库",
        //     "",
        //     "采购退货",
        //     "",
        //     "合计",
        //     "",
        //   ],
        // ]; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = [
          "商品编号",
          "商品名称",
          "条码",
          "规格",
          "基础单位",
          "数量",
          "平均成本",
          "总成本",
        ]; // 最后一行的表头数据
        const filterVal = [
          "code",
          "name",
          "barCode",
          "spec",
          "unit",
          "num",
          "price",
          "totalPrice",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          // multiHeader,
          header,
          // merges,
          data,
          filename: "库存状况表",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async getList() {
      let params = {
        ...this.searchForm,
        productTypes: this.searchForm.productTypes[1],
      };
      let res = await getCondition(params);
      if (res.code == 1) {
        this.tableData = res.data.map((v) => {
          this.UnitList.forEach((u) => {
            if (v.netContentUnit == u.id) {
              v.netContentUnitName = u.name;
            }
          });
          return v;
        });
      }
    },
    //查询按钮
    submitForm() {
      this.getList();
    },
    //获取仓库列表
    async getTheWarehouseList() {
      let res = await getTheWarehouseList();
      if (res.code == 1) {
        this.storehouse = res.data.records;
      }
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
  },
};
</script>

<style scoped>
.F {
  display: flex;
  flex-wrap: wrap;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
</style>
